import React from 'react'
import ReactDOM from 'react-dom'
import NavigationState from './navigation-state'
import NavigationController from './navigation-controller'
import NavigationStateObserver from './navigation-state-observer'

interface Props {
    title: string,
    controller: NavigationController,
    requestsState: NavigationState,
}

interface State {
    active: boolean;
}

export default class PageNavigationLink extends React.Component<Props, State> implements NavigationStateObserver {

    constructor(props: Props) {
        super(props);
        this.props.controller.addObserver(this);
        this.state = {active: false};
    }

    render() {
        return(
            <div className={this.getActive()} onClick={() => this._onClick()}>
                {this.props.title}
            </div>
        );
    }

    _onClick() {
        this.props.controller.requestState(this.props.requestsState);
    }

    public update(newState: NavigationState): void {
        this.setState({active: (newState == this.props.requestsState)});
    }

    private getActive(): string {
        return this.state.active ? "page-navlink active" : "page-navlink";
    }
}