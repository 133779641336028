import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
    action: () => void;
}

interface State {

}

export default class NavigationLogo extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
    }

    render() {
        return (
        <div id="navigation-logo"><span onClick={() => this._onClick()}>MINDPiLOT</span></div>
        );
    }

    _onClick() {
        if (this.props.action != null) {
            this.props.action();
        }
    }
}