export default class AlbumsHttpFetcher {
    readonly url: string = "https://mindpilot.im:4000/albums";
    //readonly url: string = "http://localhost:4000/albums";

    private httpResponse: any;
    private callback?: (response: any) => any;

    public retrieve(callback: (response: any) => any) {
        this.callback = callback;
        const http = new XMLHttpRequest();
        
        http.onreadystatechange = () => this._handle(http);
        http.open("GET", this.url, true);
        http.setRequestHeader("Content-Type", "text/xml");
        http.setRequestHeader("X-PINGOTHER", "");
        http.setRequestHeader("withCredentials", "true");
        console.log("Sending request for albums to " + this.url);
        http.send();
    }

    private _handle(httpObj: XMLHttpRequest) {
        if (httpObj.readyState == 4 && httpObj.status == 200) {
            this.httpResponse = JSON.parse(httpObj.responseText);
            
            if (this.callback != undefined)
                this.callback(this.httpResponse);
        }
    }
}