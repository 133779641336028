import TourDate from './tour-date'

export default class TourDateList {
    private tourDates: TourDate[];

    constructor() {
        this.tourDates = new Array<TourDate>();
    }

    public parseJson(json: any[]) {
        for (let dateJson of json) {
            let tourDate = new TourDate();
            tourDate.parseJson(dateJson);
            this.tourDates.push(tourDate);
        }
    }

    public getCount(): number {
        return this.tourDates.length;
    }

    public getTourDate(index: number): TourDate {
        if (index >= this.getCount()) {
            throw new RangeError("Requested index larger than size of collection");
        }
        return this.tourDates[index];
    }
}