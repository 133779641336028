import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
    visible: boolean,
    closeFunc: () => void
}

export default class Shader extends React.Component<Props, any> {
    public render() {
        let clazz = this.props.visible ? "shown" : "hidden";
        return <div id="shader" className={clazz} onClick={this.props.closeFunc}></div>;
    }
}