import React from 'react';
import ReactDOM from 'react-dom';
import TourDate from '../model/tour-date';

interface Props {
    key: number,
    modelObj: TourDate,
    displayDetailsFunc: (date: TourDate) => void
}

interface State {

}

export default class TourDateComp extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        if (this.props.modelObj == null) {
            return (<div></div>);
        } else {
            return (
                <tr key={this.props.modelObj.getGigId()} className="gigRow">
                    <td>{this.props.modelObj.getVenue()}</td>
                    <td>{this.props.modelObj.getDate().toLocaleString(undefined, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })}</td>
                    <td>{this.props.modelObj.getTown()}</td>
                    <td>{this.props.modelObj.getCountry()}</td>
                    <td><span className="detailsLink" 
                            onClick={() => this.props.displayDetailsFunc(this.props.modelObj)}>
                            Details...
                        </span></td>
                </tr>
            );
        }
    }
}