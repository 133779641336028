import Album from './album';

export default class AlbumList {
    private albums: Album[];

    constructor() {
        this.albums = new Array<Album>();
    }

    public parseJson(json: any[]) {
        for (let albumJson of json) {
            let album = new Album();
            album.parseJson(albumJson);
            this.albums.push(album);
        }
    }

    public getCount(): number {
        return this.albums.length;
    }

    public getAlbum(index: number): Album {
        if (index >= this.getCount()) {
            throw new RangeError("Requested index larger than size of collection");
        }
        return this.albums[index];
    }
}