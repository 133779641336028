import React from 'react'
import ReactDOM from 'react-dom'
import AlbumsHttpFetcher from '../http/albums-http-fetcher'
import AlbumList from '../model/album-list'
import AlbumComp from './album-comp'

interface Props {
}

interface State {
    fetched: boolean
}

export default class Albums extends React.Component<Props, State> {
    readonly albumsUrl: string = "http://localhost:4567/albums";
    private albums: AlbumList;

    constructor(props: Props) {
        super(props);
        this.state = {fetched: false};
        this.albums = new AlbumList();
    }

    private fetchAlbums() {
        let fetcher = new AlbumsHttpFetcher();
        fetcher.retrieve((response: any) => this._onAlbumsHttpResponse(response));
    }

    private _onAlbumsHttpResponse(json: any): void {
        this.albums = new AlbumList();
        this.albums.parseJson(json);
        this.setState({fetched: true});
    }

    componentDidMount() {
        this.fetchAlbums();
    }

    render() {
        if (this.state.fetched && this.albums.getCount() > 0) {
            let albumComps = [];
            for (let i = 0; i < this.albums.getCount(); i++) {
                var albumObj = this.albums.getAlbum(i);
                albumComps.push(<AlbumComp key={i} modelObj={albumObj} />)
            }
            return( 
                <div className="section hidden" id="music">
                    <div className="shell-container">
                        <div className="shell">
                            {albumComps}
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="section hidden" id="music">
                    <div className="album-substitute">
                        <a href="https://open.spotify.com/artist/5RHgHWnxPfJz5wkRvPvWKr" target="_blank">
                            <img className='social-icon' src='imgs/social/spotify.svg' />
                        </a>
                        <a href="https://music.apple.com/us/artist/mindpilot/1281763058" target="_blank">
                            <img className='social-icon' src='imgs/social/itunes.svg' />
                        </a>
                    </div>
                </div>
            );
        }   
    }
}