import AlbumTrack from './album-track'

interface AlbumJson {
    coverUrl: string,
    date: Date,
    title: string,
    spotifyUrl: string,
    appleUrl: string,
    tracks: []
}

export default class Album {
    private coverSrc: string;
    private title: string;
    private year: Date;
    private spotify: string;
    private itunes: string;
    private tracks: AlbumTrack[];

    constructor() {
        this.coverSrc = "";
        this.title = "";
        this.year = new Date();
        this.spotify = "";
        this.itunes = "";
        this.tracks = [];
    }

    public parseJson(input: any): void {
        let json = input as AlbumJson;

        if (json == null) {
            throw new TypeError("Album list JSON was not in a valid format");
        }
        this.coverSrc = json.coverUrl;
        this.title = json.title;
        this.year = new Date(json.date);
        this.spotify = json.spotifyUrl;
        this.itunes = json.appleUrl;
        for (let trackJson of json.tracks) {
            let track = new AlbumTrack();
            track.parseJson(trackJson);
            this.tracks.push(track);
        }
    }

    public getCoverSrc(): string {
        return this.coverSrc;
    }

    public getTitle(): string {
        return this.title;
    }

    public getYear(): string {
        return this.year.getFullYear().toString();
    }

    public getSpotify(): string {
        return this.spotify;
    }

    public getItunes(): string {
        return this.itunes;
    }

    public getTracksCount(): number {
        return this.tracks.length;
    }

    public getTrack(i: number): AlbumTrack {
        return this.tracks[i];
    }
}