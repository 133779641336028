import React from 'react'
import ReactDOM from 'react-dom'
import TourHttpFetcher from '../http/tour-http-fetcher'
import TourDateList from '../model/tour-date-list'
import TourDate from '../model/tour-date'
import TourDateComp from './tour-date'

interface Props {
    displayDetailsFunc: (gig: TourDate) => void;
}

interface State {
    fetched: boolean
}

export default class Tour extends React.Component<Props, State> {
    private tourDates: TourDateList;

    constructor(props: Props) {
        super(props);
        this.state = {fetched: false};
        this.tourDates = new TourDateList();
    }

    private fetchTourDates() {
        let fetcher = new TourHttpFetcher();
        fetcher.retrieve((response: any) => this._onHttpResponse(response));
    }

    private _onHttpResponse(json: any): void {
        this.tourDates = new TourDateList();
        this.tourDates.parseJson(json);
        this.setState({fetched: true});
    }

    componentDidMount() {
        this.fetchTourDates();
    }

    render() {
        if (this.state.fetched) {
            if (this.tourDates.getCount() > 0)
            {
                let tourDateComps = new Array<TourDateComp>(this.tourDates.getCount());
                for (let i = 0; i < this.tourDates.getCount(); i++) {
                    let dateObj = this.tourDates.getTourDate(i);
                    tourDateComps[i] = new TourDateComp({
                        key: dateObj.getGigId(),
                        modelObj: dateObj,
                        displayDetailsFunc: this.props.displayDetailsFunc
                    });
                }
                return( 
                    <div className="section hidden" id="live">
                        <div className="shell-container">
                            <div className="shell">
                                <h1>All Upcoming Gigs</h1>
                                <table id="gigs">
                                    <tbody>
                                        <tr key="header" className="header">
                                            <th>Venue</th>
                                            <th>Date</th>
                                            <th>Town</th>
                                            <th>Country</th>
                                            <th></th>
                                        </tr>
                                        {tourDateComps.map((element) => {
                                            return element.render();
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="shell-fader top"></div>
                            <div className="shell-fader bottom"></div>
                        </div>
                    </div>
                );
            } else {
                return( 
                    <div className="section hidden" id="live">
                        <div className="info-text">No live dates currently scheduled</div>
                    </div>
                );
            }
        } else {
            return(
                <div className="section hidden" id="live">
                    <div className="info-text">No live dates currently scheduled</div>
                </div>
            );
        }   
    }
}