import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
    venue: string,
    address1: string,
    address2: string,
    town: string,
    country: string,
    zoom: number
}

export default class GoogleMap extends React.Component<Props, any> {
    
    readonly apiKey = "AIzaSyBLIarTzEmRwFmMW-pK2PXe5fde33I8tLo";

    constructor(props: Props) {
        super(props);
    }

    private sanitize(props: Props): Props {
        let newProps = {
            venue: this.sanitizeStr(props.venue),
            address1: this.sanitizeStr(props.address1),
            address2: this.sanitizeStr(props.address2),
            town: this.sanitizeStr(props.town),
            country: this.sanitizeStr(props.country),
            zoom: props.zoom
        }
        return newProps;
    }

    private sanitizeStr(text: string): string {
        if (text == null) {
            return "";
        } else {
            return text.replace("/ /g", "+");
        }
    }

    render() {
        const prefix = "https://www.google.com/maps/embed/v1/place?q=J%26H+fencing/@51.9186152,-0.1976707,17z/";
        let props = this.sanitize(this.props);
        let url = prefix.concat(props.venue,
                                "+",
                                props.address1,
                                "+",
                                props.address2,
                                "+",
                                props.town,
                                "+",
                                props.country,
                                "&key=",
                                this.apiKey,
                                "&zoom=",
                                this.props.zoom.toString());
        return (
            <iframe className="googlemap" src={url}></iframe>
        );
    }
}