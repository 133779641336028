import React from 'react'
import ReactDOM from 'react-dom'

interface Props {
    videoId: string;
    width: number;
    height: number;
}

interface State {
    opts: {};
}

export default class Splash extends React.Component<Props, State> {
    private static FrameCropFactorLandscape = 0.15;
    private static FrameCropFactorPortrait = 0.15;

    constructor(props: Props, state: State) {
        super(props);
    }

    computeHeight(baseHeight: number) {
        let factor = Splash.FrameCropFactorPortrait * 2;
        // How much of the video to crop off, based on layout
        if (window.innerHeight < window.innerWidth) {
            factor = Splash.FrameCropFactorLandscape * 2;
        }

        return baseHeight * (1 + factor);
    }

    render() {
        return(
            <div className="section shown" id="splash">
                <iframe allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        width={this.props.width} height={this.computeHeight(this.props.height)}
                        src={`https://www.youtube.com/embed/${this.props.videoId}?showinfo=0&autoplay=1&amp;controls=0&amp;modestbranding=1&amp;mute=0&amp;`}
                        id="widget2"></iframe>
            </div>
        );
    }
}