export default class TourHttpFetcher {
    readonly url: string = "http://localhost:4000/gigs";

    private httpResponse: any;
    private callback?: (response: any) => any;

    public retrieve(callback: (response: any) => any) {
        this.callback = callback;
        const http = new XMLHttpRequest();
        http.onreadystatechange = () => this._handle(http);
        http.open("GET", this.url, true);
        console.log("Sending request for gigs to " + this.url);
        http.send();
    }

    private _handle(httpObj: XMLHttpRequest) {
        if (httpObj.readyState == 4 && httpObj.status == 200) {
            this.httpResponse = JSON.parse(httpObj.responseText);
            
            if (this.callback != undefined)
                this.callback(this.httpResponse);
        }
    }
}