interface TourDateJson {
    GIG_ID: number,
    DATETIME: Date,
    VENUE: string,
    VENUE_ADDRESS1: string,
    VENUE_ADDRESS2: string,
    VENUE_TOWN: string,
    VENUE_POSTAL_CODE: string,
    VENUE_COUNTRY: string,
    SUPPORT: string,
    STREAMED: number,
    EVENT_LINK: string,
    TICKETS_LINK: string,
    DESCRIPTION: string
}

export default class TourDate {

    private gigId: number;
    private venue: string;
    private dateTime: Date;
    private town: string;
    private country: string;
    private address1: string;
    private address2: string;
    private postCode: string;
    private description: string;
    private eventLink: string;
    private ticketsLink: string;
    private support: string;

    constructor() {
        this.gigId = -1;
        this.venue = "";
        this.dateTime = new Date();
        this.town = "";
        this.country = "";
        this.address1 = "";
        this.address2 = "";
        this.postCode = "";
        this.description = "";
        this.eventLink = "";
        this.ticketsLink = "";
        this.support = "";
    }

    public parseJson(json: any): void {
        json = json as TourDateJson;

        if (json == null) {
            throw new TypeError("Tour Date JSON was not in a valid format");
        }
        this.gigId = json.GIG_ID;
        this.venue = json.VENUE;
        this.dateTime = new Date(json.DATETIME);
        this.town = json.VENUE_TOWN;
        this.country = json.VENUE_COUNTRY;
        this.address1 = json.VENUE_ADDRESS1;
        this.address2 = json.VENUE_ADDRESS2;
        this.postCode = json.VENUE_POSTAL_CODE;
        this.description = json.DESCRIPTION;
        this.eventLink = json.EVENT_LINK;
        this.ticketsLink = json.TICKETS_LINK;
        this.support = json.SUPPORT;
    }

    public getGigId(): number {
        return this.gigId;
    }

    public getVenue(): string {
        return this.venue;
    }

    public getDate(): Date {
        return this.dateTime;
    }

    public getAddress1(): string {
        return this.address1;
    }

    public getAddress2(): string {
        return this.address2;
    }

    public getTown(): string {
        return this.town;
    }

    public getCountry(): string {
        return this.country;
    }

    public getPostCode(): string {
        return this.postCode;
    }

    public getDescription(): string {
        return this.description;
    }

    public getEventLink(): string {
        return this.eventLink;
    }

    public getTicketsLink(): string {
        return this.ticketsLink;
    }

    public getSupport(): string {
        return this.support;
    }
}