import React from 'react'
import ReactDOM from 'react-dom'
import NavigationLogo from './navigation-logo'
import PageNavigationLink from './page-navigation-link'
import NavigationController from './navigation-controller'
import NavigationState from './navigation-state'
import SocialNavigation from './social-navigation'

interface Props {
    mobileMenuVisible: boolean,
    controller: NavigationController,
    mobileMenuDisplayFunc: () => void;
}

interface State {
}

export default class NavigationStrip extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return(
            <div id="navigation-strip">
                <NavigationLogo action={() => this.props.controller.requestState(NavigationState.Splash)} />
                <div>
                    <div id="page-navigation-desk">
                        <SocialNavigation />
                        <PageNavigationLink title="Gigs" 
                                            controller={this.props.controller}
                                            requestsState={NavigationState.Live} />
                        <PageNavigationLink title="Music" 
                                            controller={this.props.controller}
                                            requestsState={NavigationState.Music} />
                        <PageNavigationLink title="Contact"
                                            controller={this.props.controller}
                                            requestsState={NavigationState.Contact} />
                    </div>
                    <div id="page-navigation-mob">
                        <img id='hamburger-menu'
                             className={this.props.mobileMenuVisible ? "open" : "closed"}
                             onClick={this.props.mobileMenuDisplayFunc}/>
                    </div>
                </div>
            </div>
        );
    }
}