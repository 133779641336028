import React from 'react';
import ReactDOM from 'react-dom';
import Album from '../model/album';
import AlbumTracklist from '../model/album-tracklist'
import TracklistHttpFetcher from '../http/tracklist-http-fetcher'

interface Props {
    key: number,
    modelObj: Album
}

interface State {
}

export default class AlbumComp extends React.Component<Props, State> {

    readonly urlBase: string = "";

    constructor(props: Props) {
        super(props);
    }

    public render() {
        let imgUrl = this.urlBase + this.props.modelObj.getCoverSrc();
        let tracks = [];
        for (let i = 0; i < this.props.modelObj.getTracksCount(); i++) {
            let track = this.props.modelObj.getTrack(i);
            tracks.push(
                <tr>
                    <td className="track-number">{track.getTrackNo()}</td>
                    <td className="track-title">{track.getTitle()}</td>
                    <td className="track-length">{track.getLength()}</td>
                </tr>);
        }
        return (
            <div className="album">
                <div className="album-cover">
                    <img src={imgUrl} />
                </div>
                <div className="album-details">
                    <h2 className="album-title">{this.props.modelObj.getTitle()}<span className="year">({this.props.modelObj.getYear()})</span></h2>
                    <div>
                        <table className="album-tracklist">
                            {tracks}
                        </table>
                    </div>
                    <div className='filler'></div>
                    <div className='album-links'>
                        {this.props.modelObj.getSpotify().length > 0 &&
                        <a href={this.props.modelObj.getSpotify()} target="_blank">
                            <img className='social-icon' src='imgs/social/spotify.svg' alt='Stream on Spotify' />
                        </a>}
                        {this.props.modelObj.getItunes().length > 0 &&
                        <a href={this.props.modelObj.getItunes()} target="_blank">
                            <img className='social-icon' src='imgs/social/itunes.svg' alt='Stream on Apple Music' />
                        </a>}
                    </div>
                </div>
            </div>
        );
    }
}