import React from 'react'
import ReactDOM from 'react-dom'
import PageNavigationLink from './page-navigation-link'
import NavigationController from './navigation-controller'
import NavigationState from './navigation-state'
import SocialNavigation from './social-navigation'

interface Props {
    visible: boolean,
    controller: NavigationController
}

export default class MobileNavmenu extends React.Component<Props, any> {
    public render() {
        let clazz = this.props.visible ? "shown" : "hidden";
        return (
            <div id="mobile-navmenu" className={clazz}>
                <SocialNavigation />
                <PageNavigationLink title="Gigs"
                    controller={this.props.controller}
                    requestsState={NavigationState.Live} />
                <PageNavigationLink title="Music"
                    controller={this.props.controller}
                    requestsState={NavigationState.Music} />
                <PageNavigationLink title="Contact"
                    controller={this.props.controller}
                    requestsState={NavigationState.Contact} />
            </div>
        );
    }
}