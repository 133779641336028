import React from 'react'
import ReactDOM from 'react-dom'
import NavigationStrip from './navigation/navigation-strip'
import Splash from './sections/splash'
import Tour from './sections/tour'
import Albums from './sections/albums'
import Contact from './sections/contact'
import Shader from './sections/shader'
import TourDate from './model/tour-date'
import TourDatePopup from './sections/tour-date-popup'
import NavigationDelegate from './navigation/navigation-delegate'
import NavigationController from './navigation/navigation-controller'
import MobileNavmenu from './navigation/mobile-navmenu'

interface State {
    shaderVisible: boolean,
    tourDateVisible: boolean,
    mobileMenuVisible: boolean,
    tourDate?: TourDate
}

export default class App extends React.Component<any, State> implements NavigationDelegate {

    private controller: NavigationController;

    constructor(props: any) {
        super(props);
        this.state = {
            shaderVisible: false,
            tourDateVisible: false,
            mobileMenuVisible: false
        };

        this.controller = new NavigationController(this);
    }

    private displayTourDate(gig: TourDate): void {
        this.setState({
            shaderVisible: true,
            tourDateVisible: true,
            tourDate: gig
        });
    }

    private closeTourDate(): void {
        this.setState({
            shaderVisible: false,
            tourDateVisible: false,
            mobileMenuVisible: false
        });
    }

    render() {
        return (
            <div id="container">
                <div id="content-body">
                    <NavigationStrip controller={this.controller}
                                     mobileMenuVisible={this.state.mobileMenuVisible}
                                     mobileMenuDisplayFunc={() => this.setState({mobileMenuVisible: !this.state.mobileMenuVisible})}/>
                    <Splash videoId='EvWUv8Qfp04' width={window.innerWidth} height={window.innerHeight} />
                    <Tour displayDetailsFunc={(date: TourDate) => this.displayTourDate(date)}/>
                    <Albums />
                    <Contact />
                </div>
                <Shader visible={this.state.shaderVisible}
                        closeFunc={() => this.closeTourDate()}/>
                <MobileNavmenu visible={this.state.mobileMenuVisible} controller={this.controller} />
                <TourDatePopup visible={this.state.tourDateVisible}
                               tourDate={this.state.tourDate}
                               closeFunc={() => this.closeTourDate()} />
            </div>
        );
    }

    private calculateWidth(): number {
        // Scale up based on ratio of total screen height and window height
        let totalHeight = window.screen.availHeight;
        let viewportHeight = window.innerHeight;
        let scaledWidth = window.innerWidth * (totalHeight / viewportHeight);
        return scaledWidth;
    }

    // NavigationDelegate members
    goLive() {
        this.go("live");
    }

    goMusic() {
        this.go("music");
    }

    goContact() {
        this.go("contact");
    }

    goSplash() {
        this.go("splash");
    }

    private go(sectionId: string) {
        this.hideEverything();
        let section = document.getElementById(sectionId);
        if (section != null) {
            section.classList.remove("hidden");
            section.classList.add("shown");
        }
    }

    private hideEverything() {
        // Hide all sections -- helper method.
        let sections = [
          document.getElementById("splash"),
          document.getElementById("live"),
          document.getElementById("music"),
          document.getElementById("contact"),
        ];
        sections.forEach((s) => {
            if (s != null) {
                let id = s.getAttribute("id");
                if (id == null) {
                    id = "";
                }
                s.classList.remove("shown");
                s.classList.add("hidden");
            }
        });
        this.setState({mobileMenuVisible: false});
    }

    private getElementOffset(elementId: string): number {
        let element = document.getElementById(elementId);
        let offset = element != null ? element.offsetTop - 30 : 0;
        console.log("Navigating to: " + elementId + ". Offset: " + offset);
        return offset;
    }
}

ReactDOM.render(<App />, document.getElementById('app'))