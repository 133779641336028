import React from 'react'
import ReactDOM from 'react-dom'
import TourDate from '../model/tour-date'
import GoogleMap from '../widgets/google-map'

interface Props {
    visible: boolean,
    tourDate?: TourDate,
    closeFunc: () => void
}

export default class TourDatePopup extends React.Component<Props, any> {
    
    constructor(props: Props) {
        super(props);
    }

    private static htmlify(text: string) {
        if (text == null || text == "") {
            return "";
        } else {
            return (<div>{text}</div>);
        }
    }

    private static isNullOrEmpty(text: string) {
        return (text == null || text == "");
    }

    render() {
        let clazz = this.props.visible ? 'shown' : 'hidden';
        if (this.props.tourDate == null)
        {
            return null;
        } else {
            let facebook = !this.props.tourDate.getEventLink() ? 
                undefined : 
                <div className="smalltext">
                    <a target="_blank" href={this.props.tourDate.getEventLink()}>Join the event on Facebook!</a>
                </div>;
            let ticket = !this.props.tourDate.getTicketsLink() ?
                undefined :
                <div className="smalltext">
                    Tickets on sale at: <a target="_blank" href={this.props.tourDate.getTicketsLink()}>{this.props.tourDate.getTicketsLink()}</a>
                </div>;
            return (
                <div id="tour-date-popup-shell" className={clazz}>
                    <div id="tour-date-popup" className={clazz}>
                        <div id="header">
                            <span className="close-button" onClick={this.props.closeFunc}>&#10005;</span>
                        </div>
                        <div className="tour-date-content">
                            <h2>Mindpilot at {this.props.tourDate.getVenue()}</h2>
                            <div id="content">
                                <div id="details">
                                    <div id="date">{this.props.tourDate.getDate().toLocaleString(undefined, {   
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    })}</div>
                                    <div id="address">
                                        {TourDatePopup.htmlify(this.props.tourDate.getAddress1())}
                                        {TourDatePopup.htmlify(this.props.tourDate.getAddress2())}
                                        {TourDatePopup.htmlify(this.props.tourDate.getTown())}
                                        {TourDatePopup.htmlify(this.props.tourDate.getPostCode())}
                                        {TourDatePopup.htmlify(this.props.tourDate.getCountry())}
                                    </div>
                                    <div className="smalltext">
                                        {this.props.tourDate.getDescription()}
                                    </div>
                                    {facebook}
                                    {ticket}
                                </div>
                                <div id="map">
                                    <GoogleMap venue={this.props.tourDate.getVenue()}
                                               address1={this.props.tourDate.getAddress1()}
                                               address2={this.props.tourDate.getAddress2()}
                                               town={this.props.tourDate.getTown()}
                                               country={this.props.tourDate.getCountry()}
                                               zoom={15} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}