import React from 'react'
import ReactDOM from 'react-dom'
import TourHttpFetcher from '../http/tour-http-fetcher'
import TourDateList from '../model/tour-date-list'
import TourDate from '../model/tour-date'
import TourDateComp from './tour-date'

export default class Contact extends React.Component {

    render() {
        return(
            <div className="section hidden" id="contact">
                <div className="contact-link">
                <a href={this.constructEmail(true)}>{this.constructEmail(false)}</a>
                </div>
            </div>
        );
    }

    private constructEmail(mailto: boolean): string {
        let email = "";
        if (mailto) {
            email += "mailto:"
        }
        email += "info" + "@mindpilot" + ".im";
        return email;
    }
}