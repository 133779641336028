import NavigationDelegate from './navigation-delegate'
import NavigationState from './navigation-state'
import NavigationStateObserver from './navigation-state-observer'
import NavigationStateObservable from './navigation-state-observable'

export default class NavigationController implements NavigationStateObservable {

    private delegate: NavigationDelegate;
    private state: NavigationState;
    private observers: NavigationStateObserver[] = [];

    constructor(delegate: NavigationDelegate) {
        this.delegate = delegate;
        this.state = NavigationState.Splash;
    }

    public update(newState: NavigationState): void {
        console.log("Navigation state updated: " + NavigationState[newState]);
        this.observers.forEach(o => o.update(newState));
        switch (newState) {
            case NavigationState.Splash:
                this.delegate.goSplash();
                break;
            case NavigationState.Live:
                this.delegate.goLive();
                break;
            case NavigationState.Music:
                this.delegate.goMusic();
                break;
            case NavigationState.Contact:
                this.delegate.goContact();
                break;
            default:
                throw new RangeError("Enum member not recognised.");
        }
    }

    public requestState(state: NavigationState) {
        this.state = state;
        this.update(state);
    }

    public requestSplash(): void {
        this.state = NavigationState.Splash;
    }

    public addObserver(observer: NavigationStateObserver) {
        this.observers.push(observer);
    }
}