import React from 'react'
import ReactDOM from 'react-dom'

export default class SocialNavigation extends React.Component {
    render() {
        return(
            <div id='social-navigation'>
                <a href="https://open.spotify.com/artist/5RHgHWnxPfJz5wkRvPvWKr" target="_blank">
                    <img className='social-icon' src='imgs/social/spotify.svg' />
                </a>
                <a href="https://www.youtube.com/user/mindpilotmusic" target="_blank">
                    <img className='social-icon' src='imgs/social/youtube.svg' />
                </a>
                <a href="https://www.instagram.com/mindpilotmusic/" target="_blank">
                    <img className='social-icon' src='imgs/social/instagram.svg' />
                </a>
            </div>
        )
    }
}