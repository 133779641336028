interface AlbumTrackJson {
    spotifyUrl: string,
    trackNo: number,
    duration: string,
    title: string
}

export default class AlbumTrack {
    private trackNo: number;
    private length: string;
    private title: string;

    constructor() {
        this.trackNo = -1;
        this.length = "";
        this.title = "";        
    }

    public parseJson(input: any): void {
        let json = input as AlbumTrackJson;

        if (json == null) {
            throw new TypeError("Album tracklist JSON was not in a valid format");
        }

        this.trackNo = json.trackNo;
        this.length = json.duration;
        this.title = json.title;
    }

    public getTrackNo(): number {
        return this.trackNo;
    }

    public getTitle(): string {
        return this.title;
    }

    public getLength(): string {
        return this.length;
    }
}